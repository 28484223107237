<template>
  <div>
    <div v-if="loading" class="loading-indicator">
      <v-img class="ma-0 pa-5" max-height="4rem" max-width="4rem"
        src="@/assets/images/diary_loading.gif" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    loading: false,
  }),
  mounted() {
    console.log(navigator.userAgent)
    const isIOS = /iPad|iPhone|iPod|iPad Pro|iPad Air|iPad Mini|Safari/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      this.addPullToRefreshEventListener();
    }
  },
  beforeDestroy() {
    const isIOS = /iPad|iPhone|iPod|iPad Pro|iPad Air|iPad Mini|Safari/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      this.removePullToRefreshEventListener();
    }
  },
  methods: {
    addPullToRefreshEventListener() {
      let initialY = null;
      let isPullingDown = false;

      const onTouchStart = (event) => {
      if (event.touches.length === 1 && window.scrollY === 0) {
        initialY = event.touches[0].clientY;
      }
      };

      const onTouchMove = (event) => {
        if (initialY !== null) {
          const currentY = event.touches[0].clientY;
          if (currentY > initialY + 300) { // 300px 이상 아래로 당길 때
            isPullingDown = true;
          }
        }
      };

      const onTouchEnd = () => {
        if (isPullingDown) {
          this.showLoading();
        }
        initialY = null;
        isPullingDown = false;
      };

      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);

      this.$on('hook:beforeDestroy', () => {
        document.removeEventListener('touchstart', onTouchStart);
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
      });
    },
    removePullToRefreshEventListener() {
      document.removeEventListener('touchstart', this.onTouchStart);
      document.removeEventListener('touchmove', this.onTouchMove);
      document.removeEventListener('touchend', this.onTouchEnd);
    },
    showLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        window.location.reload();
      }, 1000); // 1초 동안 로딩 표시
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  padding: 30px 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>