import Vue from 'vue'
import { isMobile } from 'mobile-device-detect';
import router from '@/router/index';
import Store from '@/store/index';
import { Set_routes } from '@/router';
import { pushRoute, replaceRoute } from '@/router/navigate';
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import {DEFAULT_COLOR} from '@/constants';

let comMixin = {
	methods: {
		mobileCheck() {
			let check;
			check = isMobile;
			return check;
		},
		routerBack() {
			replaceRoute(this.$routerHistory.previous().path, {});
		},
		goHome() {
			replaceRoute('/home', {});
		},
		goNotification() {
			pushRoute('/home/notification', {});
		},
		goMemo() {
			replaceRoute('/memo', {});
		},
		goMemoStorage() {
			replaceRoute('/memo/storage', {});
		},
		goTrash() {
			replaceRoute('/trash', {});
		},
		goCalendar() {
			replaceRoute('/calendar', {});
		},
		goMap() {
			replaceRoute('/map', {});
		},
		goMyInfo() {
			replaceRoute('/myinfo', {});
		},
		goCodeBook() {
			Store.commit('setSelectedCodeTag', undefined);
			Store.commit('setTempCodebookData', undefined);
			Store.commit('setTempCodebookTag', undefined);
			Store.commit('setCodebookDataList', []);
			replaceRoute('/codebook', {});
		},
		goBoard() {
			Store.commit('setSelectedBoardTag', undefined);
			Store.commit('setTempBoardData', undefined);
			Store.commit('setTempBoardTag', undefined);
			replaceRoute('/board', {});
		},
		goCar() {
			replaceRoute('/car', {});
		},
		goParking() {
			const parkingData = Store.getters.getParkingData;
			if(parkingData == null) {
				replaceRoute('/car/parking/parkinglot', {});
			} else {
				if(Store.state.user.email === parkingData.email) replaceRoute('/car/parking', {});
				else replaceRoute('/car/parking/parkinglot', {});
			}
		},
		goSchedule() {
			replaceRoute('/schedule', {});
		},
		goSetting() {
			replaceRoute('/setting', {});
		},
		goAdmin() {
			replaceRoute('/admin', {});
		},
		getUser() {
			return Store.state.userData.user;
		},
		getToken() {
			return Store.state.userData.user.token;
		},
		getUserName() {
			return Store.state.userData.user.userName;
		},
		getUserImageUrl() {
			return Store.state.userData.user.imageUrl;
		},
		pushRoute(url, query) {
			let urlQ = '';
			for (let item in query) {
				urlQ += '&' + item + '=' + query[item];
			}
			urlQ = urlQ.replace(/\&/, '?');
			router.push(url + urlQ).catch(()=> {});
		},
		replaceRoute(url, query) {
			let urlQ = '';
			for (let item in query) {
				urlQ += '&' + item + '=' + query[item];
			}
			urlQ = urlQ.replace(/\&/, '?');
			router.replace(url + urlQ).catch(()=> {});
		},
		getRouteTitle(path) {
			let result;
			Set_routes.forEach((route) => {
				if(route.path == path) {
					result = route.title;
				}
			});
			return result;
		},
		messageBox(text, type) {
			if(type === 'default') {
				this.$dialog.message.info(text, {
					position: 'top', timeout: 2500, color: DEFAULT_COLOR
				});
			} else if(type === 'success') {
				this.$dialog.message.success(text, {
					position: 'top', timeout: 2500
				});
			} else if(type === 'warning') {
				this.$dialog.message.warning(text, {
					position: 'top', timeout: 3000
				});
			} else if(type === 'error') {
				this.$dialog.message.error(text, {
					position: 'top', timeout: 3000
				});
			} else { // info
				this.$dialog.message.info(text, {
					position: 'top', timeout: 2500
				});
			}
		},
		pageCalculator(pageSize, total) {
			// pagesize와 data total값으로 페이지수를 계산해준다. ceil은 소수점 올림
			if(total / pageSize < 0) {
				return Math.ceil(total / pageSize);
			} else if (total / pageSize === 0) {
				return 1;
			} else {
				return Math.ceil(total / pageSize);
			}
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		fromSecondToHHMMSS(secondValue) {
			let hoursStr, minutesStr, secondsStr;
			let hours   = Math.floor(secondValue / 3600);
			let minutes = Math.floor((secondValue - (hours * 3600)) / 60);
			let seconds = secondValue - (hours * 3600) - (minutes * 60);

			if (hours < 10) { hoursStr = "0" + hours; }
			if (hours >= 10) { hoursStr = hours; }
			if (minutes < 10) { minutesStr = "0" + minutes; }
			if (minutes >= 10) { minutesStr = minutes; }
			if (seconds < 10) { secondsStr = "0" + seconds; }
			if (seconds >= 10) { secondsStr = seconds; }
			return hoursStr + ':' + minutesStr + ':' + secondsStr.toString().substr(0,2);
		},
		getCurrentDate() {
			return dayjs().format('YYYY-MM-DDTHH:mm:ss');
		},
		getFormatDate(date) {
			return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
		},
		formatDateKor(date) {
			return dayjs(date).format('YYYY년 MM월 DD일 HH:mm:ss');
		},
		enterCheck(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g,"</br>");
    },
		getDateDiff(date) { // 반환값 => 일(Day)
			const date1 = new Date();
			const date2 = new Date(date);
			const diffDate = date1.getTime() - date2.getTime();
			return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
		},
		getDateDiffDayjs(date) { // 반환값 => 일(Day)
			const date1 = dayjs()
			const date2 = dayjs(date)
			return date1.diff(date2, 'day');
		},
		findRecentEngineoil(list) {
			let recentTime = 0;
			let recentOilEvent = undefined;
			list.forEach((item) => {
				if(item.maintenanceContent.includes('엔진오일')) {
					if(recentTime < item.timeMillisecond) {
						recentTime = item.timeMillisecond;
						recentOilEvent = item;
					}
				}
			})
			return recentOilEvent;
		},
		fineRecentbulls(list) {
			let recentTime = 0;
			let recentbullsEvent = undefined;
			list.forEach((item) => {
				if(item.maintenanceContent.includes('불스원샷')) {
					if(recentTime < item.timeMillisecond) {
						recentTime = item.timeMillisecond;
						recentbullsEvent = item;
					}
				}
			})
			return recentbullsEvent
		},
		checkStringOnlyBlankPattern(str) {
			// 공백만 입력한 경우 체크
			const blank_pattern = /^\s+$/g;
			return blank_pattern.test(str); // true, false로 반환
		},
		checkStringBlankPattern(str) {
			// 문자열에 공백이 있는 경우
			const blank_pattern = /[\s]/g;
			return blank_pattern.test(str);  // true, false로 반환
		},
		checkStringSpecialPattern(str) {
			// 특수문자가 있는 경우 체크
			const special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
			return special_pattern.test(str);  // true, false로 반환
		},
		checkStringBlankWithSpecialPattern(str) {
			// 문자열에 공백 또는 특수문자가 같이 있는 경우
			if(str.search(/\W|\s/g) > -1) {
				return true;
			} else {
				return false;
			}
		},
		checkNumberAndString(str) {
			// 문자와 숫자가 아닌경우
			const regex = /[^a-zA-Z0-9가-힣ㄱ-ㅎ]/g;
			return regex.test(str);  // true, false로 반환
		},
		degStr(deg) {
			const value = Number(deg)
			if(value === 0) return '북'
			else if(value > 0 && value < 45) return '북북동'
			else if(value === 45) return '북동'
			else if(value > 45 && value < 90) return '동북동'
			else if(value === 90) return '동'
			else if(value > 90 && value < 135) return '동남동'
			else if(value === 135) return '남동'
			else if(value > 135 && value < 180) return '남남동'
			else if(value === 180) return '남'
			else if(value > 180 && value < 225) return '남남서'
			else if(value === 225) return '남서'
			else if(value > 225 && value < 270) return '서남서'
			else if(value === 270) return '서'
			else if(value > 270 && value < 315) return '서북서'
			else if(value === 315) return '북서'
			else if(value > 315 && value < 360) return '북북서'
		},
		calcScheduleNotificationDate(selectedNotificationSettingDate, beginDate, beginTime) {
			if(selectedNotificationSettingDate === '알림 없음') return '';
			else {
				let date = dayjs(beginDate + ' ' + beginTime + ':00');
				let notiDate = '';
				switch(selectedNotificationSettingDate) {
					case '정시':
						notiDate = date.subtract(1, 'minute').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '10분 전':
						notiDate = date.subtract(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '20분 전':
						notiDate = date.subtract(20, 'minute').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '30분 전':
						notiDate = date.subtract(30, 'minute').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '1시간 전':
						notiDate = date.subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '2시간 전':
						notiDate = date.subtract(2, 'hour').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '3시간 전':
						notiDate = date.subtract(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '6시간 전':
						notiDate = date.subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '12시간 전':
						notiDate = date.subtract(12, 'hour').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '1일 전':
						notiDate = date.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '3일 전':
						notiDate = date.subtract(3, 'day').format('YYYY-MM-DD HH:mm:ss');
						break;
					case '7일 전':
						notiDate = date.subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss');
						break;
				}
				// notiDate 데이터가 현재시간보다 과거인지 체크
				if(dayjs(notiDate).diff(dayjs()) < 0) return 'past';
				else return notiDate;
			}
		},
		formatNumberWithCommas(value) {
			const num = typeof value === "number" ? value.toString() : value;
			return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		checkValueUndefined(value) {
			return value === undefined || value === null ? '-' : value;
		},
		convertToMiB(value) {
			const unit = value.slice(-2);
			const number = parseFloat(value.slice(0, -2));
	
			switch (unit) {
				case 'Gi':
					return number * 1024;
				case 'Mi':
					return number;
				case 'Ki':
					return number / 1024;
				default:
					return 0;
			}
		},
		convertToGiB(value) {
			const unit = value.slice(-1).toUpperCase();
			const number = parseFloat(value.slice(0, -1));

			switch (unit) {
				case 'G':
					return Math.round(number);
				case 'M':
					return Math.round(number / 1024);
				case 'K':
					return Math.round(number / (1024 * 1024));
				default:
					return 0;
			}
		},
		calcMemoryData(data) {
      let used = 0;
      let free = 0;
      let shared = 0;
      let buffercache = 0;
      if(data.Mem && data.Swap) {
        used = this.convertToMiB(data.Mem.used) + this.convertToMiB(data.Swap.used);
        free = this.convertToMiB(data.Mem.free) + this.convertToMiB(data.Mem.available) + this.convertToMiB(data.Swap.free);
        shared = this.convertToMiB(data.Mem.shared);
        buffercache = this.convertToMiB(data.Mem.buff_cache);
      }
      console.log([used, free, shared, buffercache])
      return [used, free, shared, buffercache];
    },
		calTotalMemoryData(data) {
			let total = 0;
			if(data.Mem && data.Swap) {
				total = this.convertToMiB(data.Mem.total) + this.convertToMiB(data.Swap.total);
			}
			return total;
		},
		calcDiskData(data) {
			let used = 0;
			let available = 0;
			const total = 100;
			if(data) {
				for (let i = 0; i < data.length; i++) {
					used += parseFloat(data[i].pcent.replace('%', ''));
				}
				available = total - used;
			}
			console.log([used, available])
			return [used, available];
		},
		calcDiskAvailUsedTotal(data) {
			let available = 0;
			let used = 0;
			let total = 0;
			if(data) {
				for (let i = 0; i < data.length; i++) {
					available += this.convertToGiB(data[i].avail);
					used += this.convertToGiB(data[i].used);
					total += this.convertToGiB(data[i].size);
				}
			}
			return { available, used, total };
		},
		isTokenExpired(tokenExpiresIn) { 
			// true: 토큰 만료, false: 토큰 유효
			// tokenExpiresIn: 토큰 만료 시간 (초)
			const expiresIn = tokenExpiresIn ? tokenExpiresIn : 30 * 24 * 60 * 60;
			const current = dayjs();
			const expirationTime = dayjs.unix(expiresIn);
			return current.isAfter(expirationTime);
		},
		convertDurationToSeconds(durationStr) {
			// '1d', '1h', '1m', '1s' 문자로 받으면 초단위로 변환
			let unit = durationStr.slice(-1);
			let value = parseInt(durationStr.slice(0, -1), 10);

			switch (unit) {
				case 'd':
					return value * 24 * 60 * 60;
				case 'h':
					return value * 60 * 60;
				case 'm':
					return value * 60;
				case 's':
					return value;
				default:
					return 0;
			}
		},
	}
}
export default comMixin;