<template>
  <v-app id="app">
    <PullToRefresh>
      <router-view></router-view>
    </PullToRefresh>
  </v-app>
</template>

<script>
import { isMobile, isIOS } from 'mobile-device-detect';
import PullToRefresh from './components/layout/PullToRefresh.vue';
export default {
  name: 'Diary',
  components: {
    PullToRefresh
  },
  data: () => ({
    //
  }),
  created() {
    // this.$store.state.isMobile = this.mobileCheck();
    this.$store.state.isMobile = this.$vuetify.breakpoint.xs;
    this.$store.state.isIOS = isIOS;
  },
  mounted() {
    console.log(
      "%c다이어리",
      "font-size:30px;color:#4f66ff;",
    );
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.$store.state.isMobile = this.$vuetify.breakpoint.xs;
      this.$store.state.isTablet = this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md;
      console.log("%cisMobile-", "color:#4f66ff;", this.$store.state.isMobile);
      console.log("%cisTablet-", "color:#4f66ff;", this.$store.state.isTablet);
    },
    mobileCheck() {
			let check;
			check = isMobile;
			return check;
		},
    tabletCheck() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isPhone = /iPhone|Android/i.test(navigator.userAgent);
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
      if(isPhone) this.$store.state.isTablet = false;
      if(isTablet) this.$store.state.isTablet = true;
      if(/Macintosh/i.test(navigator.userAgent) 
				&& navigator.maxTouchPoints && navigator.maxTouchPoints > 1) this.$store.state.isTablet = true;
    }
  }
};
</script>
<style lang="scss">
@import "./styles/reset.css";
@import "./styles/button.scss";
@import "./styles/table.scss";
@import "./styles/title.scss";
@import "./styles/input.scss";
@import "./styles/common.scss";
@import "./styles/font.scss";
@import "./styles/animation.scss";
@import "./styles/variables.scss";

#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100%;
  width: 100%;
  /* min-width: 1120px; */
}
html {
  overflow-y: auto !important;
  overflow-x: auto !important;
  &::-webkit-scrollbar {
		width: 7px !important;
    height: 7px !important;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #4A4A4A !important;
	}
	&::-webkit-scrollbar-track {
		background-color: white !important;
	}
}
input:focus {
  outline: none;
}
@font-face {
  font-family: "nanumGothic";
  src: local('Nanum-Gothic'), url(./assets/fonts/NanumGothic.woff2) format("woff2"), url(./assets/fonts/NanumGothic.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nanumMyeongjo";
  src: url(./assets/fonts/NanumMyeongjo.woff2) format("woff2"), url(./assets/fonts/NanumMyeongjo.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nanumPen";
  src: url(./assets/fonts/NanumPen.woff2) format("woff2"), url(./assets/fonts/NanumPen.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "stylish";
  src: url(./assets/fonts/Stylish-Regular.woff2) format("woff2"), url(./assets/fonts/Stylish-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "hiMelody";
  src: url(./assets/fonts/HiMelody-Regular.woff2) format("woff2"), url(./assets/fonts/HiMelody-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "dokdo";
  src: url(./assets/fonts/EastSeaDokdo-Regular.woff2) format("woff2"), url(./assets/fonts/EastSeaDokdo-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cookieRun";
  src: url(./assets/fonts/CookieRun-Regular.woff2) format("woff2"), url(./assets/fonts/CookieRun-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "oegyein";
  src: url(./assets/fonts/THEAlien.woff2) format("woff2"), url(./assets/fonts/THEAlien.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bombanghak";
  src: url(./assets/fonts/HakgyoansimBombanghakR.woff2) format("woff2"), url(./assets/fonts/HakgyoansimBombanghakR.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bunpil";
  src: url(./assets/fonts/HakgyoansimBunpilR.woff2) format("woff2"), url(./assets/fonts/HakgyoansimBunpilR.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "doldam";
  src: url(./assets/fonts/HakgyoansimDoldamB.woff2) format("woff2"), url(./assets/fonts/HakgyoansimDoldamB.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "joobin";
  src: url(./assets/fonts/Ownglyph_2022_UWY_Joo_Bin-Rg.woff2) format("woff2"), url(./assets/fonts/Ownglyph_2022_UWY_Joo_Bin-Rg.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kcc";
  src: url(./assets/fonts/KCC-Ganpan.woff2) format("woff2"), url(./assets/fonts/KCC-Ganpan.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "komi";
  src: url(./assets/fonts/KNPSKkomi-Regular.woff2) format("woff2"), url(./assets/fonts/KNPSKkomi-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "kumkang";
  src: url(./assets/fonts/GOSEONGGEUMGANGNURI.woff2) format("woff2"), url(./assets/fonts/GOSEONGGEUMGANGNURI.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
.vuedl-layout {
  .v-card {
    // background-color: #171921 !important;
    // border: 1px solid #6A6A6A !important;
    .v-toolbar {
      background-color: $DEFAULT_COLOR !important;
      color: white !important;
    }
    .v-card__text {
      color: black !important;
    }
    // .v-btn {
    //   background-color: #4E4E4E;
    //   color: white !important;
    // }
  }
}
</style>