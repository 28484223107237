import Store from '@/store/index';

export function clearVuex() {
  Store.commit('setUser', null);
  Store.commit('setKeepLogin', false);
  Store.commit('setOnlyViewTitle', false);
  Store.commit('setRandomColor', false);
  Store.commit('setParkingData', null);
  Store.commit('setCodebookDataList', []);
  Store.commit('setPreviousCodebook', undefined);
  Store.commit('setNextCodebook', undefined);
  Store.commit('setGuideDialogVisible', {
    memo: true,
    car: true,
    parkinglot: true,
    document: true,
    board: true,
    trash: true,
    setting: true,
  });
  Store.commit('setServerToken', null)
  Store.commit('setWeatherData', null)
  Store.commit('setWeatherFastData', null)
  Store.commit('setDustData', null)
  Store.commit('setWebPushPermissionRequest', false)
  Store.commit('setWebPushSubscriptionTime', null)
}