import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'

const Login = () => import(/* webpackChunkName: "Login" */'../views/login/Index.vue');
const ForgetPw = () => import(/* webpackChunkName: "ForgetPw" */'../views/login/ForgetPw.vue');
const SignUp = () => import(/* webpackChunkName: "SignUp" */'../views/login/SignUp.vue');
const Layout = () => import(/* webpackChunkName: "Layout" */'../components/layout/Index.vue');
const Home = () => import(/* webpackChunkName: "Home" */'../views/home/Index.vue');
const Notification = () => import(/* webpackChunkName: "Notification" */'../views/home/Notification.vue');
const Memo = () => import(/* webpackChunkName: "Memo" */'../views/memo/Index.vue');
const MemoCreate = () => import(/* webpackChunkName: "MemoCreate" */'../views/memo/Create.vue');
const MemoModify = () => import(/* webpackChunkName: "MemoModify" */'../views/memo/Modify.vue');
const MemoDetail = () => import(/* webpackChunkName: "MemoDetail" */'../views/memo/Detail.vue');
const MemoStorage = () => import(/* webpackChunkName: "Memo" */'../views/memo/storage/Index.vue');
const MemoStorageDetail = () => import(/* webpackChunkName: "Memo" */'../views/memo/storage/Detail.vue');

const Board = () => import(/* webpackChunkName: "Board" */'../views/board/Index.vue');
const BoardDetail = () => import(/* webpackChunkName: "BoardDetail" */'../views/board/Detail.vue');

const MyInfo = () => import(/* webpackChunkName: "MyInfo" */'../views/myinfo/Index.vue');
const Inquiry = () => import(/* webpackChunkName: "Inquiry" */'../views/myinfo/Inquiry.vue');
const Trash = () => import(/* webpackChunkName: "Trash" */'../views/trash/Index.vue');
const TrashDetail = () => import(/* webpackChunkName: "TrashDetail" */'../views/trash/Detail.vue');
const Setting = () => import(/* webpackChunkName: "Setting" */'../views/setting/Index.vue');
const WebPushSetting = () => import(/* webpackChunkName: "Setting" */'../views/setting/WebPushSetting.vue');
const PassKeySetting = () => import(/* webpackChunkName: "Setting" */'../views/setting/PassKeySecurity.vue');
const CodeBook = () => import(/* webpackChunkName: "CodeBook" */'../views/codebook/Index.vue');
const CodeBookCreate = () => import(/* webpackChunkName: "CodeBookCreate" */'../views/codebook/Create.vue');
const CodeBookDetail = () => import(/* webpackChunkName: "CodeBookDetail" */'../views/codebook/Detail.vue');
const CodeBookModify = () => import(/* webpackChunkName: "CodeBookModify" */'../views/codebook/Modify.vue');
const Car = () => import(/* webpackChunkName: "Car" */'../views/car/Index.vue');
const ImageView = () => import(/* webpackChunkName: "ImageView" */'../views/support/ImageView.vue');
const CarAdd = () => import(/* webpackChunkName: "CarAdd" */'../views/car/Add.vue');
const CarModify = () => import(/* webpackChunkName: "CarModify" */'../views/car/CarModify.vue');
const Parking = () => import(/* webpackChunkName: "Parking" */'../views/parking/Index.vue');
const SelectParkinglot = () => import(/* webpackChunkName: "SelectParkinglot" */'../views/parking/SelectParkinglot.vue');
const SelfPicture = () => import(/* webpackChunkName: "SelfPicture" */'../views/parking/SelfPicture.vue');
const Bag = () => import(/* webpackChunkName: "Bag" */'../views/bag/Index.vue');
const Calendar = () => import(/* webpackChunkName: "Calendar" */'../views/calendar/Index.vue');
const Map = () => import(/* webpackChunkName: "Map" */'../views/map/Index.vue');
const Admin = () => import(/* webpackChunkName: "Admin" */'../views/admin/Index.vue');
const AdminUserList = () => import(/* webpackChunkName: "AdminUserList" */'../views/admin/UserList.vue');
const AdminInquiry = () => import(/* webpackChunkName: "AdminInquiry" */'../views/admin/Inquiry.vue');
const AdminNotice = () => import(/* webpackChunkName: "AdminNotice" */'../views/admin/Notice.vue');
const AdminBoardManage = () => import(/* webpackChunkName: "AdminBoardManage" */'../views/admin/BoardManage.vue');
const AdminUITest = () => import(/* webpackChunkName: "AdminUITest" */'../views/admin/UITest.vue');
const AdminServerStatus = () => import(/* webpackChunkName: "AdminServerStatus" */'../views/admin/ServerStatus.vue');
const AdminLogList = () => import(/* webpackChunkName: "AdminLogList" */'../views/admin/LogList.vue');
const AdminPmLogList = () => import(/* webpackChunkName: "AdminPmLogList" */'../views/admin/PmLogList.vue');
const AdminUiRefactoring = () => import(/* webpackChunkName: "AdminUiRefactoring" */'../views/admin/UiRefactoring.vue');

Vue.use(VueRouter)

export const Set_routes = [
  {
    name: 'home',
    path: '/home',
    component: Home,
    props: true
  },
  {
    path: '/home/notification',
    title: '알림',
    component: Notification,
    props: true
  },
  {
    name: 'memo',
    path: '/memo',
    title: '메모',
    component: Memo,
    props: true
  },
  {
    path: 'memo/create',
    title: '메모 작성',
    component: MemoCreate
  },
  {
    path: '/memo/modify',
    title: '메모 수정',
    component: MemoModify,
    props: true
  },
  {
    path: '/memo/detail',
    title: '메모 상세',
    component: MemoDetail,
    props: true
  },
  {
    path: '/memo/storage',
    title: '메모 보관함',
    component: MemoStorage,
    props: true
  },
  {
    path: '/memo/storage/detail',
    title: '메모 상세',
    component: MemoStorageDetail,
    props: true
  },
  {
    name: 'board',
    path: '/board',
    title: '게시판',
    component: Board,
    props: true
  },
  {
    path: '/board/detail',
    title: '게시판 상세',
    component: BoardDetail,
    props: true
  },
  {
    path: '/myinfo',
    title: '내정보',
    component: MyInfo,
    props: true
  },
  {
    path: '/myinfo/inquiry',
    title: '버그 제보 / 문의하기',
    component: Inquiry,
    props: true
  },
  {
    path: '/trash',
    title: '휴지통',
    component: Trash,
    props: true
  },
  {
    path: '/trash/detail',
    title: '메모 상세',
    component: TrashDetail,
    props: true
  },
  {
    path: '/setting',
    title: '설정',
    component: Setting,
    props: true
  },
  {
    path: '/setting/push',
    title: '알림 설정',
    component: WebPushSetting,
    props: true
  },
  {
    path: '/setting/passkey',
    title: '보안 설정',
    component: PassKeySetting,
    props: true
  },
  {
    path: '/codebook',
    title: '내문서',
    component: CodeBook,
    props: true
  },
  {
    path: '/codebook/create',
    title: '문서 작성',
    component: CodeBookCreate,
    props: true
  },
  {
    path: '/codebook/detail',
    title: '문서 상세',
    component: CodeBookDetail,
    props: true
  },
  {
    path: '/codebook/modify',
    title: '문서 수정',
    component: CodeBookModify,
    props: true
  },
  {
    path: '/car',
    title: '차계부',
    component: Car,
    props: true
  },
  {
    name: 'imageViewer',
    path: '/support/image-viewer',
    title: '이미지 상세보기',
    component: ImageView,
    props: true
  },
  {
    path: '/car/add',
    title: '내 차 등록',
    component: CarAdd,
    props: true
  },
  {
    path: '/car/modify',
    title: '내 차 수정',
    component: CarModify,
    props: true
  },
  {
    path: '/car/parking',
    title: '주차장',
    component: Parking,
    props: true
  },
  {
    path: '/car/parking/parkinglot',
    title: '주차장',
    component: SelectParkinglot,
    props: true
  },
  {
    path: '/car/parking/self-picture',
    title: '주차 위치 촬영',
    component: SelfPicture,
    props: true
  },
  {
    path: '/bag',
    title: '소지품 관리',
    component: Bag,
    props: true
  },
  {
    path: '/calendar',
    title: '달력',
    component: Calendar,
    props: true
  },
  {
    path: '/map',
    title: '지도',
    component: Map,
    props: true
  },
  {
    path: '/admin',
    title: '관리자',
    component: Admin,
    props: true
  },
  {
    path: '/admin/user-list',
    title: '유저 목록',
    component: AdminUserList,
    props: true
  },
  {
    path: '/admin/inquiry',
    title: '관리자 문의내역',
    component: AdminInquiry,
    props: true
  },
  {
    path: '/admin/notice',
    title: '공지사항 내역',
    component: AdminNotice,
    props: true
  },
  {
    path: '/admin/board-manage',
    title: '게시판 관리',
    component: AdminBoardManage,
    props: true
  },
  {
    path: '/admin/ui-test',
    title: 'UI Test',
    component: AdminUITest,
    props: true
  },
  {
    path: '/admin/server-status',
    title: '서버 상태',
    component: AdminServerStatus,
    props: true
  },
  {
    path: '/admin/log',
    title: '로그 목록',
    component: AdminLogList,
    props: true
  },
  {
    path: '/admin/log-pm',
    title: '로그(pm2) 목록',
    component: AdminPmLogList,
    props: true
  },
  {
    path: '/admin/ui-refactoring',
    title: 'UI 리팩토링 페이지',
    component: AdminUiRefactoring,
    props: true
  },
]

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/signup',
    title: '회원가입',
    component: SignUp
  },
  {
    path: '/forget-pw',
    title: '비밀번호 재설정',
    component: ForgetPw
  },
  {
    path: '',
    component: Layout,
    children: Set_routes
  },
]

export const initRoutesLength = routes.length;

const router = new VueRouter({
  routes
})

Vue.use(VueRouterBackButton, { router })

export default router
